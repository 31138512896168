/*
[Master Stylesheet]

Project: Moderat
Version: 1.1
Author: Yegor Borisenco <pimmey@pimmey.com>
Author profile: https://themeforest.net/user/pimmey


[Table of contents]

1 Global styles / body, .container, section, canvas, .main, .dark
2 Pace spinner / .pace
3 Table / table
4 Links / a, .dark a
5 Typography improvements / body, input, textarea, select, button, h2
6 Helper classes / .margin-on-medium-and-down, .nobr, .intro
7 Navigation / .navbar-fixed nav, .side-nav, .table-of-contents, .dark
8 Footer / footer
9 Hero / .spiral-buffer
10 Services / .services
11 Projects / .projects
12 Prices / .prices, .faq, .dark
13 About / .team, .philosophy, .dark
14 Journal / .journal-entries, .dark
15 Contact / .contact, .visit-us, .dark
16 Modal / .modal, .modal-close
17 Social links / .social
18 Slider / .slider .indicators .indicator-item
19 Toasts / .toast
20 Choose Demo / .choose-demo


[Color codes]

# Global colors
White:      #fff
Black:      #030301

# Used for social links in modals
Facebook:   #3b5998;
Twitter:    #4099ff;
Dribbble:   #c73b6f;
Github:     #999;
VK:         #2b587a;
Linkedin:   #4075b4;
Googleplus: #c24e40;
Pinterest:  #bd081c;

# Toast backgrounds
Error:      #ff5252
Success:    #00e676


[Typography]

# Check out http://materializecss.com/typography.html for more details
Font:       Roboto
Body:       responsive flow text (http://materializecss.com/typography.html#flow)
h2:         3.56rem/110%
h3:         2.92rem/110%
h4:         2.28rem/110%
h5:         1.64rem/110%
nav:        1rem/1.5
blockquote: 2rem/1.5
*/

@import '../../node_modules/bourbon/app/assets/stylesheets/bourbon';

@import 'components/materialize-colors';
@import 'components/variables';

/*---------------------------------------------------------------------------------------

1 Global styles

-----------------------------------------------------------------------------------------*/
@import 'components/global';

/*---------------------------------------------------------------------------------------

2 Pace spinner

-----------------------------------------------------------------------------------------*/
@import 'components/pace';

/*---------------------------------------------------------------------------------------

3 Table

-----------------------------------------------------------------------------------------*/
@import 'components/table';

/*---------------------------------------------------------------------------------------

4 Links

-----------------------------------------------------------------------------------------*/
@import 'components/links';

/*---------------------------------------------------------------------------------------

5 Typography improvements

-----------------------------------------------------------------------------------------*/
@import 'components/typography';

/*---------------------------------------------------------------------------------------

6 Helper classes

-----------------------------------------------------------------------------------------*/
@import 'components/helpers';

/*---------------------------------------------------------------------------------------

7 Navigation

-----------------------------------------------------------------------------------------*/
@import 'components/nav';

/*---------------------------------------------------------------------------------------

8 Footer

-----------------------------------------------------------------------------------------*/
@import 'components/footer';

/*---------------------------------------------------------------------------------------

9 Hero

-----------------------------------------------------------------------------------------*/
@import 'components/hero';

/*---------------------------------------------------------------------------------------

10 Services

-----------------------------------------------------------------------------------------*/
@import 'components/services';

/*---------------------------------------------------------------------------------------

11 Projects

-----------------------------------------------------------------------------------------*/
@import 'components/projects';

/*---------------------------------------------------------------------------------------

12 Prices

-----------------------------------------------------------------------------------------*/
@import 'components/prices';

/*---------------------------------------------------------------------------------------

13 About

-----------------------------------------------------------------------------------------*/
@import 'components/about';

/*---------------------------------------------------------------------------------------

14 Journal

-----------------------------------------------------------------------------------------*/
@import 'components/journal';

/*---------------------------------------------------------------------------------------

15 Contact

-----------------------------------------------------------------------------------------*/
@import 'components/contact';

/*---------------------------------------------------------------------------------------

16 Modal

-----------------------------------------------------------------------------------------*/
@import 'components/modal';

/*---------------------------------------------------------------------------------------

17 Social links

-----------------------------------------------------------------------------------------*/
@import 'components/social';

/*---------------------------------------------------------------------------------------

18 Slider

-----------------------------------------------------------------------------------------*/
@import 'components/slider';

/*---------------------------------------------------------------------------------------

19 Toasts

-----------------------------------------------------------------------------------------*/
@import 'components/toasts';

/*---------------------------------------------------------------------------------------

20 Toasts

-----------------------------------------------------------------------------------------*/
@import 'components/choose-demo';
