.team {
    [class*='-clip'] {
        @include transition(all .4s $ease-out-circ);
    }

    .triangle-clip {
        @include prefixer(clip-path, polygon(50% 0%, 0% 100%, 100% 100%, 100% 100%), webkit spec);

        &:hover {
            @include prefixer(clip-path, polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%), webkit spec);
        }
    }

    .rhombus-clip {
        @include prefixer(clip-path, polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%), webkit spec);

        &:hover {
            @include prefixer(clip-path, polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%), webkit spec);
        }
    }

    .x-clip {
        @include prefixer(clip-path, polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%), webkit spec);

        &:hover {
            @include prefixer(clip-path, polygon(0% 0%, 0% 0%, 0% 50%, 0% 100%, 0% 100%, 50% 100%, 100% 100%, 100% 100%, 100% 50%, 100% 0%, 100% 0%, 50% 0%), webkit spec);
        }
    }

    .rabbet-clip {
        @include prefixer(clip-path, polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%), webkit spec);

        &:hover {
            @include prefixer(clip-path, polygon(0% 0%, 0% 0%, 0% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%, 0% 100%, 0% 100%, 0% 100%), webkit spec);
        }
    }

    .pentagon-clip {
        @include prefixer(clip-path, polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%), webkit spec);

        &:hover {
            @include prefixer(clip-path, polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%), webkit spec);
        }
    }

    .heptagon-clip {
        @include prefixer(clip-path, polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%), webkit spec);

        &:hover {
            @include prefixer(clip-path, polygon(50% 0%, 100% 0%, 100% 60%, 100% 100%, 0% 100%, 0% 60%, 0% 0%), webkit spec);

        }
    }

    .parallelogram-clip {
        @include prefixer(clip-path, polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%), webkit spec);

        &:hover {
            @include prefixer(clip-path, polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%), webkit spec);
        }
    }

    .bevel-clip {
        @include prefixer(clip-path, polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%), webkit spec);

        &:hover {
            @include prefixer(clip-path, polygon(0% 0%, 100% 0%, 100% 0%, 100% 100%, 100% 100%, 00% 100%, 0% 100%, 0% 0%), webkit spec);
        }
    }

    @media #{$small-and-down} {
        .title,
        .name {
            text-align: center;
        }
    }
}

.philosophy {
    padding: 5rem 0 4rem;

    @media #{$small-and-down} {
        padding: 3rem 0 2rem;
    }

    .tabs {
        height: auto;
        background-color: transparent;

        .tab {
            height: auto;
            letter-spacing: normal;
            color: $white;
            text-transform: none;

            h5 {
                @media #{$small-and-down} {
                    display: none;
                }
            }

            .philosophy-number {
                @media #{$large-and-up} {
                    display: none;
                }
            }

            a {
                color: transparentize($white, .4);

                &.active {
                    color: $white;
                }
            }
        }
    }

    .tab-contents {
        h5 {
            display: none;

            @media #{$small-and-down} {
                display: block;
            }
        }
    }

    .indicator {
        height: 3px;
        background-color: $white;
    }
}

.dark {
    .philosophy {
        .tabs .tab a {
            color: transparentize($black, .75);

            &.active {
                color: $black;
            }
        }

        .indicator {
            background-color: $black;
        }
    }
}
