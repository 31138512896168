// Media query ranges
$small-screen-up: 601px;
$medium-screen-up: 993px;
$large-screen-up: 1201px;
$small-screen: 600px;
$medium-screen: 992px;
$large-screen: 1200px;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})";
$large-and-up: "only screen and (min-width : #{$medium-screen-up})";
$small-and-down: "only screen and (max-width : #{$small-screen})";
$medium-and-down: "only screen and (max-width : #{$medium-screen})";
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})";

// Colors
$white: #fff;
$black: #030301;

// Used in social.scss
$facebook: #3b5998;
$twitter: #4099ff;
$dribbble: #c73b6f;
$github: #999;
$vk: #2b587a;
$linkedin: #4075b4;
$googleplus: #c24e40;
$pinterest: #bd081c;

// Used for toasts
$error: color('red', 'accent-2');
$success: color('green', 'accent-3');
