.prices {
    .info {
        display: inline-block;
        padding: 0 1rem;
        cursor: help;
        opacity: .5;

        &:hover {
            opacity: 1;
        }
    }

    h5 {
        margin-bottom: 1em;
    }

    .divider {
        margin: 1.5rem 0;
    }

    @media #{$small-and-down} {
        .price-box {
            text-align: center;
        }

        .info {
            position: absolute;
        }
    }
}

.faq {
    .collapsible {
        border: 0;
        box-shadow: none;
    }

    .collapsible-header {
        min-height: 5rem;
        padding: 0;
        line-height: 5rem;
        background: transparent;
    }

    .collapsible-body p {
        padding: 2rem 0;
    }
}

.dark {
    .collapsible-header,
    .collapsible-body {
        border-bottom-color: transparentize($black, .5);
    }
}
