.services {
    i {
        display: block;
        margin: 2rem 0 1rem;
        font-size: 3rem;
    }

    @media #{$small-and-down} {
        .service-items {
            text-align: center;
        }
    }
}
