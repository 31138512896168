.choose-demo {
    canvas {
        width: 80%;
        margin: 0 auto;
    }

    h1 {
        margin: 8rem 0;
        text-transform: uppercase;
        letter-spacing: 7px;
    }

    h4 {
        margin-bottom: 4rem;
        letter-spacing: -1px;
    }

    a {
        color: #191919;
    }
}
